import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import usd from "../assets/images/usd.png";
import chgr_logo from "../assets/images/chgr_logo.png";
import try_logo from "../assets/images/try_logo.png";
import arrow from "../assets/images/arrow-down.png";
import depositarrow from "../assets/images/depositarrow.png";
import cross from "../assets/images/cross.png";
import Bank from "./DepositComponent/Bank";
import Crypto from "./DepositComponent/Crypto";
import { useSelector } from "react-redux";  // Redux'tan kullanıcı verilerini almak için ekledim.

function Deposit({ isBellOpen }) {
  const history = useNavigate();
  const [depositSection, setDepositSection] = useState("deposit");

  // Redux'tan isKycCompleted durumunu kontrol ediyoruz
  const { isKycCompleted } = useSelector((state) => state.auth); 

  const handleDepositClick = () => {
    if (!isKycCompleted) {
      // Eğer KYC doğrulaması yapılmamışsa KYC sayfasına yönlendirme yapılıyor.
      history("/kyc");
    } else {
      // KYC doğrulaması tamamlanmışsa banka veya kripto seçenekleri gösteriliyor.
      setDepositSection("bank");
    }
  };

  return (
    <React.Fragment>
      {/* Başlık */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: { xs: "90%", md: "400px" }, // Mobil uyumluluk için
          margin: "0px auto", // Ortalamak için margin
          position: "relative",
          padding: "16px 0",
          backgroundColor: "#131313",
          // Bildirim üzerine çıkmasını sağlamak için yüksek z-index
          transition: "transform 0.3s ease", // Geçiş animasyonu
          transform: isBellOpen ? "translateX(-150px)" : "translateX(0)", // Notification açıldığında sola kaydır
        }}
      >
        {depositSection !== "deposit" && (
          <img
            src={depositarrow}
            alt="new"
            style={{ position: "absolute", left: "0px", cursor: "pointer" }}
            onClick={() => setDepositSection("deposit")}
          />
        )}
        <Typography
          sx={{
            fontSize: 20,
            color: "#fff",
            fontWeight: 500,
            fontFamily: '"Inter", sans-serif',
          }}
        >
          Yatır
        </Typography>
        <img
          src={cross}
          alt="new"
          style={{
            position: "absolute",
            right: "0px",
            cursor: "pointer",
            zIndex: 1100,
          }}
          onClick={() => history("/")}
        />
      </Box>

      {/* İçerik */}
      <Box
        sx={{
          paddingBottom: "12px",
          maxWidth: { xs: "90%", md: "400px" }, // Mobil uyumluluk için
          margin: "24px auto", // Ortalamak için margin
          padding: "16px", // İç boşluk
          transition: "transform 0.3s ease", // Geçiş animasyonu
          transform: isBellOpen ? "translateX(-150px)" : "translateX(0)", // Notification açıldığında sola kaydır
        }}
      >
        {depositSection === "deposit" && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                cursor: "pointer",
              }}
              onClick={handleDepositClick}  // Yatırma butonuna tıklandığında KYC sayfasına yönlendirme işlemi yapılır.
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={try_logo}
                  alt="trylogo"
                  style={{ marginRight: "10px" }}
                />
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  TRY
                </Typography>
              </Box>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => setDepositSection("crypto")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={usd}
                  alt="usdlogo"
                  style={{ marginRight: "10px" }}
                />
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  USDT
                </Typography>
              </Box>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => setDepositSection("crypto")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={chgr_logo}
                  alt="chgrlogo"
                  style={{ marginRight: "10px" }}
                />
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    display: "flex",
                    alignItems: "center",
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  CHGR
                </Typography>
              </Box>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>
          </Box>
        )}

        {depositSection === "bank" && (
          <Box>
            <Bank />
          </Box>
        )}

        {depositSection === "crypto" && (
          <Box>
            <Crypto />
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

export default Deposit;
