import { Box, Typography } from "@mui/material";
import React from "react";


const TokenItem = ({ setSellCurrency, sellCurrency, balance, name, logo, currency }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 16px",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#131313",
                cursor: 'pointer',
                border: sellCurrency === currency ? "2px solid #1772E1" : '2px solid transparent',
            }}
            onClick={() => setSellCurrency(currency, balance)}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <img
                    src={logo}
                    alt="logo"
                    style={{ marginRight: "10px" }}
                ></img>
                <Typography
                    sx={{
                        color: "#fff",
                        fontWeight: 600,
                        fontSize: 20,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        fontFamily: '"Inter", sans-serif',
                    }}
                >
                    {name}
                </Typography>
            </Box>
            <Typography
                sx={{
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: 19,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    fontFamily: '"SF Mono", monospace',
                }}
            >
                {balance}
                {/* {balances.length ? balances[1].balance : 0} */}
            </Typography>
        </Box>
    )
}

export default TokenItem;
