import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import arrow from '../../assets/images/website/launcharrow.png'
import heroimage from '../../assets/images/website/hero_image.png'
import cacao from '../../assets/images/website/kakao.png'
import nut from '../../assets/images/website/nut.png'
import charget from '../../assets/images/website/charget.png'
import { Link, useNavigate } from "react-router-dom";
function HeroSection() {
  const navigate = useNavigate()

  const cryptos = [
    { name: 'CHGR', fullName: 'Charget Token', price: '6.49 TL', icon: charget },
    { name: 'POVCACAO', fullName: 'PovCacao Token', price: '222.31 TL', icon: cacao },
    { name: 'POVNUT', fullName: 'PovNut Token', price: '127.94 TL', icon: nut },    
];

  return (
    <React.Fragment>
      <Box className='website_hero'>
        <Container maxWidth='xl'>
          <Box sx={{ padding: { xs: '66px 0px 80px', lg: '80px 0px 140px' }, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: { xs: 'column', lg: 'row' } }}>
            <Box sx={{ width: { xs: '100%', lg: '50%', xl: '40%' } }}>
              <Typography sx={{ color: '#fff', fontWeight: 700, fontFamily: '"Inter", sans-serif', fontSize: { xs: 36, lg: 54 }, lineHeight: { xs: '44px', lg: '65px' }, textAlign: { xs: 'center', lg: 'start' } }}>
              Tokenizasyonun gücüyle tanışın!
              </Typography>
              <Typography sx={{ textAlign: { xs: 'center', lg: 'start' }, color: '#fff', fontWeight: 400, fontFamily: '"Inter", sans-serif', fontSize: { xs: 18, lg: 22 }, padding: { xs: '24px 0px 40px', lg: '24px 0px 60px' } }}>
              
              Povego ile güvenli bir biçimde, fiziksel karşılığa sahip geleneksel varlıklara (RWA) yatırım yapma imkanına kavuşun. Blokzincirin güvenliği ile rahatça alım satım yapın.
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: { xs: 'center', lg: 'start' } }}>
                <Button
                  sx={{
                    fontWeight: 500,
                    fontSize: 18,
                    color: "#E63B1F",
                    backgroundColor: "#fff",
                    borderRadius: "48px",
                    padding: "16px 35px",
                    textTransform: "none",
                    fontFamily: '"Inter", sans-serif',
                    "&::first-letter": {
                      textTransform: "capitalize",
                    },
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                  onClick={() => navigate("/login")}
                >
                  Hemen Katıl
                  <img src={arrow} alt='arrow' style={{ marginLeft: '10px' }}></img>
                </Button>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '95%', sm: '50% ', xl: '50%' }, justifyContent: { xs: 'center', lg: 'center' } }}>
              <Box sx={{ height: { xs: '231px', lg: '450px' , xl: '550px' }, marginTop: { xs: '44px', lg: '0px' },  }}>
              <div className="crypto-prices">
                <div className="header">
                <Link
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  margin: "0px 16px",
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#a3a3a3",
                  marginLeft: "auto",
                }}
                to={'/urunler'}
              >
                Tüm Tokenları Gör
              </Link>
                </div>
                <div className="crypto-list">
                    {cryptos.map((crypto, index) => (
                        <div key={index} className="crypto-item"> 
                            <div className="crypto-info">
                                <img src={crypto.icon} alt={crypto.name} className="crypto-icon" />
                                <div className="crypto-details">
                                    <span className="crypto-name">{crypto.name}</span>
                                    <span className="crypto-full-name">{crypto.fullName}</span>
                                </div>
                            </div>
                            <div className="crypto-price">{crypto.price}</div>
                            <button className="buy-sell-btn" 
                              onClick={() => navigate("/login")}
                            >AL / SAT</button>
                        </div>
                    ))}
                </div>
            </div>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default HeroSection