import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from './pages/Login';
import Signup from './pages/Signup';
import HomePage from './pages/HomePage';
import WebHomepage from './pages/WebHomepage';
import Layout from './layout';
import ResetPassword from './pages/ResetPassword';
import CreatePassword from './pages/CreatePassword';
import WebLayout from './weblayout';
import WhyPovego from './pages/WhyPovego';
import HowItWorks from './pages/HowItWorks';
import Products from './pages/Products';
import Operation from './pages/Operation';
import AboutUs from './pages/AboutUs';
import WhatIsPovego from './pages/WhatIsPovego';
import Team from './pages/Team';
import Personal from './pages/Personal';
import Declaration from './pages/Declaration';
import Usage from './pages/Usage';
import Cookies from './pages/Cookies';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import KYCPage from './pages/KYCPage';
import Settings from './components/Settings';
import Notification from './components/Notification';
import { useSelector } from 'react-redux';
import Swap from './components/HomeComponent/Swap';

function Router() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <div>
      <Routes>
        <Route path='/' element={<WebLayout><WebHomepage /></WebLayout>} />
        <Route path='/neden' element={<WebLayout><WhyPovego /></WebLayout>} />
        <Route path='/nasil' element={<WebLayout><HowItWorks /></WebLayout>} />
        <Route path='/ne' element={<WebLayout><WhatIsPovego /></WebLayout>} />
        <Route path='/takim' element={<WebLayout><Team /></WebLayout>} />
        <Route path='/urunler' element={<WebLayout><Products /></WebLayout>} />
        <Route path='/operasyon' element={<WebLayout><Operation /></WebLayout>} />
        <Route path='/cerezler' element={<WebLayout><Cookies /></WebLayout>} />
        <Route path='/kisisel' element={<WebLayout><Personal /></WebLayout>} />
        <Route path='/aydinlatma' element={<WebLayout><Declaration /></WebLayout>} />
        <Route path='/kullanim' element={<WebLayout><Usage /></WebLayout>} />
        <Route path='/hakkimizda' element={<WebLayout><AboutUs /></WebLayout>} />
        <Route path='/anasayfa' element={isLoggedIn ? <HomePage /> : <Navigate to='/login' />} />
        <Route path='/anasayfa/:id' element={isLoggedIn ? <HomePage /> : <Navigate to='/login' />} />
        <Route path='/kyc' element={isLoggedIn ? <Layout><KYCPage /></Layout> : <Navigate to='/login' />} />
        <Route path='/login' element={!isLoggedIn ? <Layout><Login /></Layout> : <Navigate to='/anasayfa' />} />
        <Route path='/kayit' element={!isLoggedIn ? <Layout><Signup /></Layout> : <Navigate to='/anasayfa' />} />
        <Route path='/reset-password' element={<Layout><ResetPassword /></Layout>} />
        <Route path='/create-password' element={<Layout><CreatePassword /></Layout>} />
        <Route path='/deposit' element={isLoggedIn ? <Layout><Deposit /></Layout> : <Navigate to={'/login'} />} />
        <Route path='/withdraw' element={isLoggedIn ? <Layout><Withdraw /></Layout> : <Navigate to={'/login'} />} />
        <Route path='/home/settings' element={isLoggedIn ? <Layout><Settings /></Layout> : <Navigate to='/login' />} />
        <Route path='/home/notification' element={<Layout><Notification/></Layout>}/> {/* Ensure this path matches */}
        <Route path='/home/swap' element={<Layout><Swap/></Layout>}/> {/* Ensure this path matches */}
        {/* Add the Settings route here */}
      </Routes>
    </div>
  );
}

export default Router;
