import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, IconButton, Input } from "@mui/material";

import usd from "../../assets/images/usd.png";
import arrow from "../../assets/images/arrow-down.png";
import TRYLogo from "../../assets/images/try_logo.png";
import CHGRLogo from "../../assets/images/chgr_logo.png";
import SwapImg from "../../assets/images/swap-arrow.svg";

import TokenItem from "./TokenItem";

import { dispatch, useSelector } from "../../store";
import { usePovegoContext } from "../../context";
import { Swap } from "../../store/reducers/crypto";
import toast from "react-hot-toast";

function Sell() {
  const navigate = useNavigate();
  const { showCurreny, setShowCurreny } = usePovegoContext();
  const { balances } = useSelector((state) => state.crypto);
  const { isLoggedIn, userId, isKycCompleted } = useSelector((state) => state.auth);

  const [type, setType] = useState(false);
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [sellBalance, setSellBalance] = useState(0);
  const [sellCurrency, setSellCurrency] = useState(1);

  const seletCurrency = (currency, balance) => {
    setSellCurrency(currency);
    setSellBalance(balance);
    const amount = (sellAmount * balances[currency - 1].price) / balances[1].price;
    setBuyAmount(amount);
  };

  useEffect(() => {
    setShowCurreny(true);
  }, [isLoggedIn, setShowCurreny]);

  useEffect(() => {
    if (balances) {
      setSellBalance(balances.length ? balances[0].balance : 0);
    }
  }, [balances]);

  const handleAmountFocus = () => {
    if (!isKycCompleted) {
      navigate("/kyc", { state: { showAlert: true } });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      userId: userId,
      fromAssetId: !type ? balances[sellCurrency - 1].id : balances[1].id,
      toAssetId: type ? balances[sellCurrency - 1].id : balances[1].id,
      fromAmount: !type ? sellAmount : buyAmount,
      toAmount: type ? sellAmount : buyAmount,
    };

    if (
      (!type && (balances[sellCurrency - 1].balance < sellAmount || sellAmount === 0)) ||
      (type && (Number(buyAmount) === 0 || balances[1].balance < Number(buyAmount)))
    ) {
      toast.error("Giriş hatası");
      return;
    }

    dispatch(Swap(data));

    if (!isLoggedIn) {
      navigate("/home");
    } else {
      navigate("/home/swap");
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        {/* sell section  */}

        {!type && (
          <Box
            sx={{
              backgroundColor: "#1b1b1b",
              position: "relative",
              borderRadius: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: { xl: "110px", sm: "110px", xs: "110px" },
                padding: "0px 16px",
              }}
            >
              <Box xs={8}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Sat
                </Typography>
                <Input
                  className="swap-input"
                  value={sellAmount}
                  onChange={(e) => {
                    if (
                      /^\d+$/.test(Number(e.target.value)) ||
                      /^\d+\.\d+$/.test(e.target.value) ||
                      Number(e.target.value) === 0
                    ) {
                      setSellAmount(
                        e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value
                      );
                      setBuyAmount(
                        Number(e.target.value) * balances[sellCurrency - 1].price / balances[1].price
                      );
                    }
                  }}
                  onFocus={handleAmountFocus}
                  sx={{
                    fontSize: 33,
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: '"SF Mono", monospace',
                    border: "none",
                    maxWidth: "200px",
                  }}
                />
              </Box>
              <Box
                xs={4}
                sx={{
                  color: "#7d7d7d",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "40px",
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCurreny(!showCurreny)}
                >
                  {sellCurrency === 1 ? (
                    <img src={usd} alt="usd" style={{ paddingRight: "10px" }} />
                  ) : (
                    <img src={TRYLogo} alt="usd" style={{ paddingRight: "10px" }} />
                  )}
                  <Typography
                    sx={{
                      marginRight: "14px",
                      fontFamily: '"Inter", sans-serif',
                      fontWeight: 600,
                    }}
                  >
                    {sellCurrency === 1 ? "USDT" : "TRY"}
                  </Typography>
                  <img
                    src={arrow}
                    alt="arrow"
                    style={{
                      transform:
                        showCurreny && isLoggedIn ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </Box>
                {isLoggedIn && (
                  <Box
                    sx={{
                      paddingTop: "3px",
                      color: "#7d7d7d",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: '"SF Mono", monospace',
                        fontWeight: 500,
                      }}
                    >
                      Bakiye:{" "}
                      <span style={{ fontFamily: '"SF Mono", monospace' }}>
                        {sellBalance}
                      </span>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{ paddingBottom: "12px" }}
              className={`content ${showCurreny && isLoggedIn ? "visible" : "hidden"}`}
            >
              {balances.length > 0 ? (
                <>
                  <TokenItem
                    setSellCurrency={seletCurrency}
                    sellCurrency={sellCurrency}
                    balance={Number(Number(balances[2].balance).toFixed(2))}
                    name={balances[2].symbol}
                    logo={TRYLogo}
                    currency={balances[2].id}
                  />

                  <Box pt={0.5} />

                  <TokenItem
                    setSellCurrency={seletCurrency}
                    sellCurrency={sellCurrency}
                    balance={Number(Number(balances[0].balance).toFixed(2))}
                    name={balances[0].symbol}
                    logo={usd}
                    currency={balances[0].id}
                  />
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        )}

        {type && (
          <Box
            sx={{
              backgroundColor: "#1b1b1b",
              position: "relative",
              borderRadius: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: { xl: "122px", sm: "122px", xs: "122px" },
                padding: "0px 16px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Sat
                </Typography>
                <Input
                  className="swap-input"
                  value={buyAmount}
                  onChange={(e) => {
                    if (
                      /^\d+$/.test(Number(e.target.value)) ||
                      /^\d+\.\d+$/.test(e.target.value) ||
                      Number(e.target.value) === 0
                    ) {
                      setBuyAmount(
                        e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value
                      );
                      setSellAmount(
                        Number(e.target.value) * balances[1].price / balances[sellCurrency - 1].price
                      );
                    }
                  }}
                  onFocus={handleAmountFocus}
                  sx={{
                    fontSize: 33,
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: '"SF Mono", monospace',
                    border: "none",
                    maxWidth: "200px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  color: "#fff",
                  fontSize: 20,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={CHGRLogo}
                  alt="usd"
                  style={{ paddingRight: "10px" }}
                />
                <Typography
                  sx={{
                    fontFamily: '"Inter", sans-serif',
                    fontWeight: 600,
                  }}
                >
                  CHGR
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {isLoggedIn && type && (
          <Box
            sx={{
              color: "#7d7d7d",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              cursor: "pointer",
              zIndex: 10,
            }}
          >
            <Typography
              sx={{
                zIndex: 10,
                marginRight: "14px",
                marginTop: "-50px",
                fontSize: "14px",
                fontFamily: '"SF Mono", monospace',
                fontWeight: 500,
              }}
            >
              Bakiye: {balances.length ? Number(Number(balances[1].balance).toFixed(4)) : 0}
            </Typography>
          </Box>
        )}

        <Box sx={{ position: "relative" }}>
          <IconButton
            style={{
              color: "white",
              backgroundColor: "#1b1b1b",
              borderRadius: "12px",
              border: "4px solid #131313",
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translate(-50% , -0%)",
              zIndex: 10,
            }}
            onClick={() => setType(!type)}
          >
            <img src={SwapImg} style={{ height: "22px", width: "22px" }} alt="longarrow" />
          </IconButton>
        </Box>

        {!type && (
          <Box
            sx={{
              backgroundColor: "#1b1b1b",
              position: "relative",
              borderRadius: "16px",
              marginTop: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: { xl: "122px", sm: "122px", xs: "122px" },
                padding: "0px 16px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Al
                </Typography>
                <Input
                  className="swap-input"
                  value={buyAmount}
                  onChange={(e) => {
                    if (
                      /^\d+$/.test(Number(e.target.value)) ||
                      /^\d+\.\d+$/.test(e.target.value) ||
                      Number(e.target.value) === 0
                    ) {
                      setBuyAmount(
                        e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value
                      );
                      setSellAmount(
                        Number(e.target.value) * balances[1].price / balances[sellCurrency - 1].price
                      );
                    }
                  }}
                  onFocus={handleAmountFocus}
                  sx={{
                    fontSize: 33,
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: '"SF Mono", monospace',
                    border: "none",
                    maxWidth: "200px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  paddingTop: "15px",
                  color: "#fff",
                  fontSize: 20,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={CHGRLogo}
                  alt="usd"
                  style={{ paddingRight: "10px" }}
                />
                <Typography
                  sx={{
                    fontFamily: '"Inter", sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {balances.length && balances[1].symbol}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {isLoggedIn && !type && (
          <Box
            sx={{
              color: "#7d7d7d",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              cursor: "pointer",
              zIndex: 10,
            }}
          >
            <Typography
              sx={{
                zIndex: 10,
                marginRight: "14px",
                marginTop: "-50px",
                fontSize: "14px",
                fontFamily: '"SF Mono", monospace',
                fontWeight: 500,
              }}
            >
              Bakiye: {balances.length ? Number(Number(balances[1].balance).toFixed(2)) : 0}
            </Typography>
          </Box>
        )}

        {type && (
          <Box
            sx={{
              backgroundColor: "#1b1b1b",
              position: "relative",
              borderRadius: "16px",
              marginTop: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: { xl: "110px", sm: "110px", xs: "110px" },
                padding: "0px 16px",
              }}
            >
              <Box xs={8}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Al
                </Typography>
                <Input
                  className="swap-input"
                  value={sellAmount}
                  onChange={(e) => {
                    if (
                      /^\d+$/.test(Number(e.target.value)) ||
                      /^\d+\.\d+$/.test(e.target.value) ||
                      Number(e.target.value) === 0
                    ) {
                      setSellAmount(
                        e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value
                      );
                      setBuyAmount(
                        Number(e.target.value) * balances[sellCurrency - 1].price / balances[1].price
                      );
                    }
                  }}
                  onFocus={handleAmountFocus}
                  sx={{
                    fontSize: 33,
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: '"SF Mono", monospace',
                    border: "none",
                    maxWidth: "200px",
                  }}
                />
              </Box>
              <Box
                xs={4}
                sx={{
                  color: "#7d7d7d",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "40px",
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCurreny(!showCurreny)}
                >
                  {sellCurrency === 1 ? (
                    <img src={usd} alt="usd" style={{ paddingRight: "10px" }} />
                  ) : (
                    <img src={TRYLogo} alt="usd" style={{ paddingRight: "10px" }} />
                  )}
                  <Typography
                    sx={{
                      marginRight: "14px",
                      fontFamily: '"Inter", sans-serif',
                      fontWeight: 600,
                    }}
                  >
                    {sellCurrency === 1 ? "USDT" : "TRY"}
                  </Typography>
                  <img
                    src={arrow}
                    alt="arrow"
                    style={{
                      transform:
                        showCurreny && isLoggedIn ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </Box>
                {isLoggedIn && (
                  <Box
                    sx={{
                      paddingTop: "3px",
                      color: "#7d7d7d",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: '"SF Mono", monospace',
                        fontWeight: 500,
                      }}
                    >
                      Bakiye:{" "}
                      <span style={{ fontFamily: '"SF Mono", monospace' }}>
                        {Number(Number(sellBalance).toFixed(4))}
                      </span>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{ paddingBottom: "12px" }}
              className={`content ${showCurreny && isLoggedIn ? "visible" : "hidden"}`}
            >
              {balances.length > 0 ? (
                <>
                  <TokenItem
                    setSellCurrency={seletCurrency}
                    sellCurrency={sellCurrency}
                    balance={Number(Number(balances[2].balance).toFixed(2))}
                    name={balances[2].symbol}
                    logo={TRYLogo}
                    currency={balances[2].id}
                  />

                  <Box pt={0.5} />

                  <TokenItem
                    setSellCurrency={seletCurrency}
                    sellCurrency={sellCurrency}
                    balance={Number(Number(balances[0].balance).toFixed(2))}
                    name={balances[0].symbol}
                    logo={usd}
                    currency={balances[0].id}
                  />
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Button
        sx={{
          fontWeight: 600,
          fontSize: 18,
          color: "#fff",
          backgroundColor: "#E63B1F",
          borderRadius: "16px",
          padding: "13px 0px",
          marginTop: "10px",
          textTransform: "none",
          fontFamily: '"Inter", sans-serif',
          "&::first-letter": {
            textTransform: "capitalize",
          },
          "&:hover": {
            background: "#E63B1F",
          },
        }}
        disabled={
          balances.length > 0 &&
          ((!type && (balances[sellCurrency - 1].balance < sellAmount || sellAmount === 0)) ||
            (type && (Number(buyAmount) === 0 || balances[1].balance < Number(buyAmount))))
        }
        fullWidth
        onClick={(e) => (isLoggedIn ? onSubmit(e) : navigate("/login"))}
      >
        {isLoggedIn ? "Takas özeti" : "Giriş Yap"}
      </Button>
    </React.Fragment>
  );
}

export default Sell;
