import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Container } from "@mui/material";

import Deposit from "../components/Deposit";
import Withdraw from "../components/Withdraw";
import Settings from "../components/Settings";
import Sell from "../components/HomeComponent/Sell";
import Swap from "../components/HomeComponent/Swap";
import Notification from "../components/Notification";

import { usePovegoContext } from "../context";
import { dispatch, useSelector } from "../store";
import { getAssets, getBalances } from "../apis";
import { UpdateAssets, UpdateBalances } from "../store/reducers/crypto";
import Layout from "../layout";

function Homepage() {
  const { id } = useParams()
  const { siderOpen } = usePovegoContext();
  const { userId } = useSelector((state) => state.auth);

  const homecomponent = [
    { href: undefined, com: <Sell /> },
    { href: 'swap', com: <Swap /> },
    { href: 'notification', com: <Notification /> },
    { href: 'deposit', com: <Deposit /> },
    { href: 'withdraw', com: <Withdraw /> },
    { href: 'settings', com: <Settings /> },
  ]

  useEffect(() => {

    const fetchCrypto = async () => {
      try {
        const assets = await getAssets()
        dispatch(UpdateAssets(assets))
        const balances = await getBalances(userId)

        const sorted = await assets.map((asset) => {
          let balance = 0;
          const data = balances.filter((item) => item.asset_id === asset.id)
          if (data.length > 0) {
            balance = data[0].balance
          }

          return {
            ...asset,
            balance
          }
        })
        dispatch(UpdateBalances(sorted))

      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (userId) {
      fetchCrypto();
    }
  }, [userId]);


  return (
      <Layout>
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { xs: "32px", sm: "0px", xl: "32px" },
          maxWidth: "400px",
          minWidth: { sm: "400px" },
          margin: "auto",
          position: "relative",
        }}
      >
        {homecomponent.map((item, i) => (
          <Box key={i} sx={{ position: 'relative' }} className={`sidebarcontent ${siderOpen ? "shifted" : ""}`}>
            {id === item.href && (
              <Box >
                {item.com}
              </Box>
            )}
          </Box>
        ))}

      </Box>
    </Container>
      </Layout>
  );
}

export default Homepage;